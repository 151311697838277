.block-header-programm {
  @screen lg {
    padding-top: 104px;
  }
  @screen 5xl {
    padding-top: 108px;
  }
  @screen 6xl {
    padding-top: 115px;
  }
  h1 {
    margin-bottom: 36px;
    @screen 3xl {
      $map: (
        1728px: 36px,
        1920px: 40px,
        2220px: 46px,
        2560px: 53px
      );
      @include fs('margin-bottom', $map);
    }
  }

  .w-tagline {
    h1 {
      margin-bottom: 14px;
      @screen 3xl {
        $map: (
          1728px: 15px,
          1920px: 17px,
          2220px: 20px,
          2560px: 24px
        );
        @include fs('margin-bottom', $map);
      }
    }

    .tagline {
      margin-bottom: 24px;
      @screen 3xl {
        $map: (
          1728px: 27px,
          1920px: 30px,
          2220px: 35px,
          2560px: 40px
        );
        @include fs('margin-bottom', $map);
      }
    }
  }
}