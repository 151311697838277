form {
  // checkbox
  .checkbox-wrapper {
    &.acceptance-wrapper {
      margin-top: 48px;
      @screen 2xl {
        @include cm-fs('margin-top', 48px);
      }
    }
    .custom-checkbox {
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
      position: relative;
      margin-right: 16px;
      border: 1px solid theme('colors.white');
      border-radius: 50%;
      @screen 2xl {
        @include cm-fs('min-width', 16px);
        @include cm-fs('min-height', 16px);
        @include cm-fs('width', 16px);
        @include cm-fs('height', 16px);
        @include cm-fs('border-width', 1px);
      }
      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: theme('colors.white');
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        @include transition-fx;
        @screen 2xl {
          @include cm-fs('width', 12px);
          @include cm-fs('height', 12px);
        }
      }
      &.checked {
        &:before {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
    .acceptance {
      @extend p;
      color: theme('colors.white');
      a {
        display: inline-block;
        margin-left: 5px;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}