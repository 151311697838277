.block-page-form {
  padding-top: 40px;
  @screen lg {
    $map: (
      768px: 50px,
      1024px : 60px,
      1536px: 70px,
      1728px: 80px
    );
    @include fs('padding-top', $map);
  }
  .title-onglet, span.title-card {
    font-size: 19px;
    @screen lg {
      $map: (
        1280px: 21px,
        2220px: 23px
      );
      @include fs('font-size', $map);
    }
  }
  .card-form {
    background: rgba(255, 255, 255, 0.10);
    &.background-pro {
      position: relative;
      overflow: hidden;
      &::before {
        content:'PRO';
        position: absolute;
        top: 0%;
        right: 0%;
        transform: translate(30%,100%) rotate(45deg);
        width: 200px;
        height: 30px;
        background-color: theme('colors.text');
        z-index: 5;
        color: theme('colors.white');
        text-align: center;
        padding-top: 2px;
        @screen lg {
          transform: translate(35%,45%) rotate(45deg);
        }
        @screen xl {
          transform: translate(35%,45%) rotate(45deg);
        }
      }
    }
  }
  .content-card {
    font-size: 14px;
    @screen lg {
      $map: (
        1280px: 16px,
        2220px: 19px
      );
      @include fs('font-size', $map);
    }
  }
  form {
    input, label {
      color: theme('colors.white');
    }
    .input-wrapper {
      &.class-textarea {
        label {
          padding: 10px 28px;
        }
      }
      textarea {
        height: 200px;
        width: 100%;
        background: theme('backgroundImage.dark-linear-gradient');
        border: none;
        border-radius: 10px;
        padding: 10px 28px;
      }
    }
    .custom-select {
      .select-container > div {
        width: 100%;
        flex:1;
      }
      .react-select-container {
        flex-shrink: 0;
        flex: 1;
      }
      .react-select__control {
        background: theme('backgroundImage.dark-linear-gradient');
        color: theme('colors.white');
        font-family: theme('fontFamily.sans');
        border: none;
        border-radius: 10px;
        padding: 10px 28px;
        font-size: 16px;
        min-height: 60px;
        cursor: pointer;
        outline: 0;
        z-index: 1;
        box-shadow: none;
        flex: 1;
        width: 100%;
        @screen 2xl {
          font-size: 18px;
        }
        &--menu-is-open {
          border: none;
          .react-select__indicators {
            opacity: 1;
            svg {
              transform: rotate(180deg);
            }
          }
        }
        &--is-focused {
          .react-select__indicator {
            &.react-select__dropdown-indicator {
              opacity: 1;
              color: theme('colors.white');
            }
          }
        }
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__single-value, .react-select__input-container, .react-select__placeholder {
          color: theme('colors.white');
        }

        .react-select__placeholder {
          color: theme('colors.white');
        }
      }
      .react-select__menu {
        background-color: theme('colors.primary');
        border-left: 2px solid theme('colors.text');
        border-right: 2px solid theme('colors.text');
        border-bottom: 2px solid theme('colors.text');
        border-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        color: theme('colors.white');
        width: 99%;
        left: 50%;
        transform:translateX(-50%) translateY(-5px);
        z-index: 10;
        font-family: theme('fontFamily.sans');

        .react-select__option {
          transition: .2s ease;
          cursor: pointer;
          padding-left: 2%;
          background-color: theme('colors.primary');
          &:hover {
            background-color: #1d2d45;
          }

          &.first-level {
            $map: (2100px:22px,
            768px: 16px,
            992px: 18px,
            1200px: 20px,
            1920px: 20px
            );
            @include fs('font-size', $map);

          }
          &.second-level {
            color: theme('colors.secondary');
            $map: (
              1200px: 14px,
              1920px:16px,
              2100px:18px
              );
            @include fs('font-size', $map);
            padding-left: 4%;
          }
        }
      }
      &:not(:first-child) {
        padding-top: 21px;
      }
    }
  }
}