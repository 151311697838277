.block-hp-introduction {
  padding-top: 0;
  h2 {
    br {
      display: none;
      @screen xs {
        display: block;
      }
    }
  }
}