// TYPOGRAPHY
a {
  transition: color ease .3s;
}

h1, .h1 {
  $map: (
    375px: 35px,
    1280px: 51px,
    1536px: 64px,
    1728px: 66px,
    1920px: 81px,
    2220px: 94px,
    2560px: 109px
  );
  @include fs('font-size', $map);
  line-height: 109%;
  font-weight: 100;
  @screen lg {
    font-weight: 200;
  }
  &.hp-title {
    letter-spacing: .3em;
    text-transform: uppercase;
  }
}

h2, .h2 {
  font-size: 31px;
  line-height: 136%;
  font-weight: 200;
  @screen lg {
    line-height: 113%;
    $map: (
      1024px: 37px,
      1536px: 56px,
      1728px: 62px,
      1920px: 69px,
      2220px: 80px,
      2560px: 92px
    );
    @include fs('font-size', $map);
  }
}

h3, .h3 {
  $map: (
    375px: 23px,
    1279px: 35px,
    1536px: 40px,
    1728px: 45px,
    1920px: 50px,
    2220px: 58px,
    2560px: 67px
  );
  @include fs('font-size', $map);
  line-height: 139%;
  font-weight: 200;
  @screen lg {
    line-height: 117%;
  }
}

h4, .h4 {
  $map: (
    375px: 20px,
    500px: 22px,
    1536px: 25px,
    1728px: 27px,
    1920px: 31px,
    2220px: 36px,
    2560px: 42px
  );
  @include fs('font-size', $map);
  line-height: 140%;
  font-weight: 200;
  @screen lg {
    line-height: 122%;
  }
}

h5, .h5 {
  $map: (
    375px: 18px,
    1536px: 22px,
    1728px: 24px,
    1920px: 26px,
    2220px: 30px,
    2560px: 35px
  );
  @include fs('font-size', $map);
  line-height: 150%;
  font-weight: 200;
  @screen lg {
    line-height: 140%;
  }
}

.title-w-em {
  em {
    font-style: normal;
    color: theme('colors.text');
  }
}

.title-w-img {
  h2 {
    font-size: 35px;
    span {
      display: inline;
      vertical-align: middle;
    }
    br {
      display: none;
    }
    @screen lg {
      display: block;
      font-size: 49px;
    }
    @screen xl {
      font-size: 55px;
    }
    @screen 3xl {
      @include cm-fs('font-size', 60px);
    }
  }
}

h1, h2, h3, h4, h5, .suptitle, .uncommon-heading {
  br {
    display: none;
    @screen xs {
      display: block;
    }
  }
  br + .word {
    &:before {
      content: "\00a0";
      display: inline-block;
      @screen xs {
        display: none;
      }
    }
  }
  .word {
    overflow: hidden;
    .inside {
      display: inline-block;
    }
  }
}

p {
  font-size: 14px;
  font-weight: 300;
  line-height: 167%;
  @screen 3xl {
    $map: (
      1728px: 16px,
      1920px: 17px,
      2220px: 20px,
      2560px: 23px
    );
    @include fs('font-size', $map);
  }
}

.suptitle {
  font-size: 16px;
  font-weight: 200;
  line-height: 162%;
  letter-spacing: .3em;
  text-transform: uppercase;
  @screen 3xl {
    $map: (
      1728px: 18px,
      1920px: 20px,
      2220px: 23px,
      2560px: 27px
    );
    @include fs('font-size', $map);
  }
}

ul:not(.no-list-style) {
  list-style: none;
  margin-bottom: 20px;
  @screen 3xl {
    $map: (
      1728px: 20px,
      1920px: 22px,
      2220px: 25px,
      2560px: 29px
    );
    @include fs('margin-bottom', $map)
  }
  li {
    @extend p;
    position: relative;
    margin-bottom: 0 !important;
    padding-left: 15px;
    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 2px;
      position: absolute;
      top: 10px;
      left: 0;
      border-radius: 50%;
      background-color: white;
      @screen 3xl {
        $map: (
          1728px: 10px,
          1920px: 14px,
          2220px: 17px,
          2560px: 20px
        );
        @include fs('top', $map);
      }
    }
  }
}

// predef font-size
.ft-8 {
  $map: (
    375px: 8px,
    1728px: 8px,
    2560px: 12px
  );
  @include fs('font-size', $map);
}
.ft-9 {
  $map: (
    375px: 9px,
    1536px: 9px,
    2560px: 15px
  );
  @include fs('font-size', $map);
}

.ft-9-to-14 {
  $map: (
    375px: 9px,
    1536px: 14px,
    1728px: 15px,
    1920px: 17px,
    2220px: 20px,
    2560px: 23px
  );
  @include fs('font-size', $map);
}

.ft-9-to-18 {
  $map: (
    375px: 9px,
    1536px: 18px,
    1728px: 19px,
    1920px: 22px,
    2220px: 26px,
    2560px: 30px
  );
  @include fs('font-size', $map);
}

.ft-10 {
  font-size: 10px;
  @screen 3xl {
    @include cm-fs('font-size', 10px);
  }
}

.ft-11 {
  $map: (
    375px: 11px,
    1920px: 14px,
    2220px: 16px,
    2560px: 18px
  );
  @include fs('font-size', $map);
}

.ft-12 {
  $map: (
    375px: 12px,
    1536px: 12px,
    1728px: 13.5px,
    1920px: 15px,
    2220px: 17px,
    2560px: 20px
  );
  @include fs('font-size', $map);
}

.ft-13 {
  $map: (
    375px: 13px,
    1536px: 13px,
    1728px: 14px,
    1920px: 16px,
    2220px: 18.5px,
    2560px: 21.5px
  );
  @include fs('font-size', $map);
}

.ft-14 {
  $map: (
    375px: 14px,
    1728px: 14px,
    1920px: 17.5px,
    2220px: 20px,
    2560px: 23px
  );
  @include fs('font-size', $map);
}
.ft-15 {
  $map: (
    375px: 12px,
    1728px: 15px,
    1920px: 16.5px,
    2220px: 19px,
    2560px: 22px
  );
  @include fs('font-size', $map);
}
.ft-20 {
  $map: (
    375px: 16px,
    1536px: 20px,
    1728px: 20px,
    1920px: 22px,
    2220px: 25px,
    2560px: 29px
  );
  @include fs('font-size', $map);
}
.ft-25 {
  $map: (
    375px: 18px,
    1536px: 25px,
    1728px: 25px,
    1920px: 27px,
    2220px: 32px,
    2560px: 37px
  );
  @include fs('font-size', $map);
}
.ft-27 {
  $map: (
    375px: 27px,
    1536px: 27px,
    1728px: 27px,
    1920px: 27px,
    2220px: 32px,
    2560px: 37px
  );
  @include fs('font-size', $map);
}

.ft-30 {
  $map: (
    375px: 30px,
    1536px: 30px,
    1728px: 30px,
    1920px: 27px,
    2220px: 32px,
    2560px: 37px
  );
  @include fs('font-size', $map);
}

.ft-35 {
  $map: (
    375px: 28px,
    1536px: 35px,
    1728px: 35px,
    1920px: 38px,
    2220px: 44px,
    2560px: 51px
  );
  @include fs('font-size', $map);
}

.ft-45 {
  $map: (
    375px: 28px,
    1536px: 45px,
    1728px: 45px,
    1920px: 50px,
    2220px: 57px,
    2560px: 66px
  );
  @include fs('font-size', $map);
}

.ft-55 {
  $map: (
    375px: 28px,
    1536px: 55px,
    1728px: 55px,
    1920px: 61px,
    2220px: 70px,
    2560px: 81px
  );
  @include fs('font-size', $map);
}

// font-families
// Avenir
.font-Avenir {
  font-family: var(--font-avenir);
}

// Futura
.font-Futura {
  font-family: var(--font-futura);
}

// Mathilde
.font-Mathilde {
  font-family: var(--font-mathilde);
}
h1.font-Mathilde {
    $map: (
      375px: 55px,
      1280px: 83px,
      1536px: 100px,
      1728px: 112px,
      1920px: 124px,
      2220px: 143px,
      2560px: 166px
    );
    @include fs('font-size', $map);
    line-height: 75%
}
.h4.font-Mathilde {
  $map: (
    375px: 50px,
    500px: 53px,
    1536px: 63px,
    1728px: 69px,
    1920px: 75px,
    2220px: 85px,
    2560px: 97px
  );
  @include fs('font-size', $map);
  line-height: 57%;
}
.subsubmenuitem.font-Mathilde {
  font-size: 42px;
  line-height: 57%;
}
.product-btn {
  .font-Mathilde {
    $map: (
      375px: 32px,
      1728px: 32px,
      1920px: 35.5px,
      2220px: 41px,
      2560px: 47px
    );
    @include fs('font-size', $map);
    line-height: 75%;
  }
}


// NellaSue
.font-NellaSue {
  font-family: var(--font-nellasue);
}
h1.font-NellaSue {
  line-height: 145%;
}
.h4.font-NellaSue {
  $map: (
    375px: 30px,
    1536px: 35px,
    1728px: 38px,
    1920px: 41px,
    2220px: 46px,
    2560px: 52px
  );
  @include fs('font-size', $map);
  line-height: 80%;
}

.subsubmenuitem.font-NellaSue {
  font-size: 26px;
  line-height: 80%;
}

// Sphère
.font-Sphere {
  font-family: var(--font-sphere);
}
.product-btn {
  .font-NellaSue {
    $map: (
      375px: 20px,
      1728px: 20px,
      1920px: 22px,
      2220px: 25px,
      2560px: 29px
    );
    @include fs('font-size', $map);
    line-height: 0 !important;
  }
}

.block-products-mea {
  .armchair-card {
    .h4.font-Sphere, .h4.font-Futura {
      $map: (
        375px: 22px,
        500px: 23px,
        1536px: 40px,
        1728px: 41px,
        1920px: 47px,
        2220px: 56px,
        2560px: 66px
      );
      @include fs('font-size', $map);
    }
  }
}