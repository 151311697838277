.hfpt, .block-index-0 {
  padding-top: 90px;
  @screen lg {
    padding-top: 104px;
  }
  @screen 5xl {
    padding-top: 108px;
  }
  @screen 6xl {
    padding-top: 115px;
  }
}

.common-block-padding {
  padding-top: 20px;
  padding-bottom: 20px;
  @screen sm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @screen md {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @screen lg {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @screen 3xl {
    $map: (
      1728px: 50px,
      1920px: 55px,
      2220px: 64px,
      2560px: 72.5px
    );
    @include fs('padding-top', $map);
    @include fs('padding-bottom', $map);
  }
}

.common-block-padding-top {
  padding-top: 20px;
  @screen sm {
    padding-top: 30px;
  }
  @screen md {
    padding-top: 40px;
  }
  @screen lg {
    padding-top: 50px;
  }
  @screen 3xl {
    $map: (
      1728px: 50px,
      1920px: 55px,
      2220px: 64px,
      2560px: 72.5px
    );
    @include fs('padding-top', $map);
  }
}

.common-block-padding-bottom {
  padding-bottom: 20px;
  @screen sm {
    padding-bottom: 30px;
  }
  @screen md {
    padding-bottom: 40px;
  }
  @screen lg {
    padding-bottom: 50px;
  }
  @screen 3xl {
    $map: (
      1728px: 50px,
      1920px: 55px,
      2220px: 64px,
      2560px: 72.5px
    );
    @include fs('padding-bottom', $map);
  }
}

.fb-block-padding {
  padding-top: 40px;
  padding-bottom: 20px;
  @screen sm {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  @screen md {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  @screen lg {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  @screen 3xl {
    $map: (
      1728px: 100px,
      1920px: 110px,
      2220px: 128px,
      2560px: 145px
    );
    $map-b: (
      1728px: 50px,
      1920px: 55px,
      2220px: 64px,
      2560px: 72.5px
    );
    @include fs('padding-top', $map);
    @include fs('padding-bottom', $map-b);
  }
}

.fb-block-padding-top {
  padding-top: 40px;
  @screen sm {
    padding-top: 60px;
  }
  @screen md {
    padding-top: 80px;
  }
  @screen lg {
    padding-top: 100px;
  }
  @screen 3xl {
    $map: (
      1728px: 100px,
      1920px: 110px,
      2220px: 128px,
      2560px: 145px
    );
    @include fs('padding-top', $map);
  }
}

.lb-block-padding {
  padding-top: 20px;
  padding-bottom: 40px;
  @screen sm {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  @screen md {
    padding-top: 40px;
    padding-bottom: 80px;
  }
  @screen lg {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @screen 3xl {
    $map-t: (
      1728px: 50px,
      1920px: 55px,
      2220px: 64px,
      2560px: 72.5px
    );
    $map: (
      1728px: 100px,
      1920px: 110px,
      2220px: 128px,
      2560px: 145px
    );
    @include fs('padding-top', $map-t);
    @include fs('padding-bottom', $map);
  }
}

.lb-block-padding-bottom {
  padding-bottom: 40px;
  @screen sm {
    padding-bottom: 60px;
  }
  @screen md {
    padding-bottom: 80px;
  }
  @screen lg {
    padding-bottom: 100px;
  }
  @screen 3xl {
    $map: (
      1728px: 100px,
      1920px: 110px,
      2220px: 128px,
      2560px: 145px
    );
    @include fs('padding-bottom', $map);
  }
}