.block-page-banner, .block-post-banner {
  .main-wrapper {
    padding: 30px;
    @screen md {
      padding: 45px 60px;
    }
    @screen lg {
      padding: 60px 90px;
    }
    @screen xl {
      padding: 60px 120px;
    }
    @screen 2xl {
      $map-y: (
        1536px: 60px,
        2560px: 100px
      );
      $map-x: (
        1536px: 120px,
        2560px: 200px
      );
      @include fs('padding-top', $map-y);
      @include fs('padding-bottom', $map-y);
      @include fs('padding-left', $map-x);
      @include fs('padding-right', $map-x);
    }
  }
}