.block-buy-buttons {
  .grid {
    .col {
      &.col-mobile-1 {
        grid-column: span 1 / span 1;
      }

      &.col-mobile-2 {
        grid-column: span 2 / span 2;
      }

      &.col-tablet-1 {
        @screen md {
          grid-column: span 12 / span 12;
        }
      }
      &.col-tablet-2 {
        @screen md {
          grid-column: span 6 / span 6;
        }
      }
      &.col-tablet-3 {
        @screen md {
          grid-column: span 4 / span 4;
        }
      }

      &.col-desktop-1 {
        @screen lg {
          grid-column: span 12 / span 12;
        }
      }
      &.col-desktop-2 {
        @screen lg {
          grid-column: span 6 / span 6;
        }
      }
      &.col-desktop-3 {
        @screen lg {
          grid-column: span 4 / span 4;
        }
      }
      &.col-desktop-4 {
        @screen lg {
          grid-column: span 3 / span 3;
        }
      }
    }
  }
}