footer {
  .w-contact {
    .suptitle {
      br {
        @screen md {
          display: none;
        }
      }
    }
  }

  .footer-links {
    a {
      padding-block: 10px;
      @screen lg {
        padding-block: 0;
      }
    }
  }
}