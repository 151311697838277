.block-map {
  #map {
    .marker-ping {
      @include fs('width', (375px: 30px, 1536px: 80px));
      width: 30px;
      aspect-ratio: 49/132;
      z-index: 20;
      transition: background ease .3s;
      &.active {
        z-index: 10;
        @include fs('width', (375px: 48px, 1536px: 130px));
        &.type-showroom {
          background: url('../../../../public/images/svgs/marker-showroom-active.svg') no-repeat top center / 100% 50%;
        }
        &.type-reseller {
          background: url('../../../../public/images/svgs/marker-reseller-active.svg') no-repeat top center / 100% 50%;
        }
        @screen 3xl {
          @include cm-fs('width', 130px);
        }
      }
      &:hover {
        &.type-showroom {
          background: url('../../../../public/images/svgs/marker-showroom-hover.svg') no-repeat top center / 100% 50%;
        }
        &.type-reseller {
          background: url('../../../../public/images/svgs/marker-reseller-hover.svg') no-repeat top center / 100% 50%;
        }
      }
      @screen 3xl {
        @include cm-fs('width', 80px);
      }
      &.type-showroom {
        background: url('../../../../public/images/svgs/marker-showroom.svg') no-repeat top center / 100% 50%;
      }
      &.type-reseller {
        background: url('../../../../public/images/svgs/marker-reseller.svg') no-repeat top center / 100% 50%;
      }
    }
  }

  .marker-popups {
    .return-link {
      pointer-events: none;
      opacity: 0;
      @include transition-fx;
      &.active {
        pointer-events: all;
        opacity: 1;
      }
      &:hover {
        color: theme('colors.action');
        cursor: pointer;
      }
    }

    .marker-popup {
      position: absolute;
      opacity: 0;
      padding: 20px;
      border-radius: 20px;
      background: theme('backgroundImage.dark-linear-gradient');
      backdrop-filter: blur(6px);
      pointer-events: none;
      &.reseller-popup {
        width: 66%;
      }
      &.active {
        position: static;
        opacity: 1;
        pointer-events: all;
      }
      @screen sm {
        padding: 20px 40px;
      }
      @screen md {
        &.showroom-popup {
          width: 66%;
        }
      }
      @screen lg {
        padding: 10px 20px;
        &.showroom-popup, &.reseller-popup {
          width: max-content;
        }
      }
      @screen xl {
        padding: 20px 30px;
      }
      @screen 2xl {
        padding: 35px 40px;
      }
      @screen 3xl {
        @include cm-fs('padding-block', 35px);
        @include cm-fs('padding-inline', 40px);
      }

      .mp-title {
        @include fs('font-size', (375px: 24px, 1536px: 34px));
        font-weight: 500;
        line-height: 103%;
        @screen 3xl {
          @include cm-fs('font-size', 34px);
        }
      }
      .mp-jobtitle {
        margin-bottom: 30px;
        @include fs('font-size', (375px: 18px, 1536px: 20px));
        font-weight: 300;
        font-style: italic;
        @screen 3xl {
          @include cm-fs('margin-bottom', 30px);
          @include cm-fs('font-size', 20px);
        }
      }

      .img-wrapper {
        margin-top: 18px;
        margin-bottom: 27px;
        @screen 3xl {
          @include cm-fs('margin-top', 18px);
          @include cm-fs('margin-bottom', 27px);
        }
      }

      .localisation, .schedules {
        margin-bottom: 8px;
        @screen 3xl {
          @include cm-fs('margin-bottom', 8px);
        }
        .icon {
          font-size: 20px;
          @screen 3xl {
            @include cm-fs('font-size', 20px);
          }
        }
        .city, .schedules-title {
          @include fs('font-size', (375px: 20px, 1536px: 25px));
          font-weight: 500;;
        }
      }

      .address {
        margin-bottom: 20px;
        @screen lg {
          margin-bottom: 30px;
        }
        @screen 3xl {
          @include cm-fs('margin-bottom', 30px);
        }
      }
    }
  }
}