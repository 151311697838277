// body (and w temp bg white)
body {
  &.bck-spa-white {
    // block triggering bck spa white
    h1, h2, h3, h4, h5, .suptitle {
      color: theme('colors.primary');
      text-shadow: unset;
    }

    // block triggering bck spa white
    .block-bck-spa-white {
      h1, h2, h3, h4, h5, .suptitle {
        color: theme('colors.primary');
        text-shadow: unset;
      }
    }

    .block-common-header {
      .breadcrumbs {
        a {
          color: theme('colors.primary');
          &::before {
            background: theme('colors.primary');
            opacity: .3;
          }
        }
      }
      .intro p {
        color: theme('colors.primary');
      }
    }

    // block hp blsystems
    .block-hp-blsystems {
      .slide-desc, a span {
        color: theme('colors.primary');
      }
      .swiper-pagination-bullets .swiper-pagination-bullet {
        border-color: theme('colors.primary');
        &.swiper-pagination-bullet-active {
          background: theme('colors.primary');
        }
      }
      .systems-slider-nav {
        .prev, .next {
          .icon, .slide-title {
            color: theme('colors.primary');
          }
        }
      }
    }
    // block hp banner
    .block-hp-banner {
      .subtitle, h4, p, div,a .text {

        color: theme('colors.primary');
      }
    }
    // block products Mea
    .block-products-mea {
      .armchair-card {
        p { color: theme('colors.primary'); }
        .btn-cta-2-2-group span{ color: theme('colors.primary'); }
      }
    }
    // block Reassurance
    .block-reassurance {
      .card {
        img { filter: invert(.66); }
        span { color: theme('colors.primary'); }
      }
    }
    // block hp company banner
    .block-hp-company-banner {
      h1, h2, h3, h4, h5, .suptitle {
        color: theme('colors.white');
      }
    }
    // audio component inside archive relax programms
    .block-archive-relax-programms {
      .suptitle, h2 {
        color: theme('colors.secondary');
      }
      .audio-component {
        .ac-wrapper, .icon-wrapper {
          border-color: theme('colors.secondary');
        }
        .icon, .time {
          color: theme('colors.secondary');
        }
        .progress-bar {
          filter: none;
        }
      }
    }
    // block cta test
    .block-cta-test {
      h2, .h5 {
        @include transition-fx;
        color: theme('colors.primary');
      }
    }
    // banner accessories
    .block-banner-accessories {
      h1, h2, h3, h4, h5 {
        color: theme('colors.white');
      }
    }
  }
}

// base layout (and w fill bg white)
.base-layout {
  // block triggering bck spa white
  .block-bck-spa-white {
    h1, h2, h3, h4, h5, .suptitle {
      color: theme('colors.white');
    }
  }

  // block hp relax programm btn
  .block-relax-programms {
    .btn-relax-prog {
      background-color: theme('colors.white');
      &:hover {
        background-color: theme('colors.action');
      }
    }
  }

  // audio component inside archive relax programms
  .block-archive-relax-programms {
    .suptitle, h2 {
      color: theme('colors.white');
    }
    .audio-component {
      .ac-wrapper, .icon-wrapper {
        border-color: theme('colors.white');
      }
      .icon, .time {
        color: theme('colors.white');
      }
      .progress-bar {
        filter: invert(100%);
      }
    }
  }
  // block hp banner
  .block-hp-banner {
    .subtitle, h4, p, div, a .text {
      @include transition-fx;
      color: theme('colors.white');
    }
  }

  // block cta test
  .block-cta-test {
    h2, .h5 {
      @include transition-fx;
      color: theme('colors.white');
    }
  }
}