.block-relax-programms {
  .suptitle, .title {
    @screen md {
      br {
        display: none;
      }
    }
  }
  .programm-line {
    .swiper-wrapper{
      .swiper-slide {
        width: max-content
      }
    }
  }
}
.programm-card {
  &:not(.archive-programm-card) {
    @include fs('width', (375px: 130px, 1024px: 236px, 1728px: 265px));
  }
  padding: 20px 10px;
  @screen lg {
    padding: 35px 20px;
  }
  @screen 3xl {
    @include cm-fs('padding-inline', 20px);
    @include cm-fs('padding-block', 35px);
    &:not(.archive-programm-card) {
      @include cm-fs('width', 265px);
    }
  }
  .programm-img {
    width: auto;
    height: 75px;
    margin-bottom: 10px;
    @screen lg {
      height: 135px;
    }
    @screen xl {
      height: 135px;
    }
    @screen 3xl {
      @include cm-fs('height', 135px);
      @include cm-fs('margin-bottom', 30px);
    }
  }

  .programm-title {
    @include fs('height', (375px: 33.5px, 1728px: 71.5px));
    @screen 3xl {
      @include cm-fs('height', 71.5px);
    }
  }
}