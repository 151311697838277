.block-bl-experience {
  .grid {
    padding: 40px 20px;
    @screen lg {
      padding: 60px 0;
    }
    @screen 3xl {
      $map: (
        1728px: 60px,
        2560px: 88px
      );
      @include fs('padding-top', $map);
      @include fs('padding-bottom', $map);
    }
    .title {
      margin-bottom: 40px;
      @screen 3xl {
        $map: (
          1728px: 40px,
          2560px: 60px
        );
        @include fs('margin-bottom', $map);
      }
    }
    .clicker {
      &.open {
        margin-top: 20px;
        @screen 3xl {
          $map: (
            1728px: 20px,
            2560px: 30px
          );
          @include fs('margin-top', $map);
        }
      }
    }
  }
  .triptych-img {
    height: 80px;
    @screen lg {
      height: 110px;
    }
    @screen xl {
      height: 145px;
    }
    @screen 3xl {
      $map: (
        1728px: 145px,
        2560px: 215px
      );
      @include fs('height', $map);
    }
  }
}