.block-color-choice {
  .color-choice-item {
    .tooltip-text {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 10;
      padding-inline: 5px;
      border-radius: 5px;
      background-color: theme('colors.white');
      color: theme('colors.text');
      white-space: nowrap;
      opacity: 0;
      transform: translate(-50%, calc(-100% - 15px));
      @include transition-fx;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }
    }

    &:hover {
      .tooltip-text {
        opacity: 1;
        transform: translate(-50%, calc(-100% - 10px));
      }
    }
  }
}