.block-common-header {
  padding-top: 0;
  @screen lg {
    $map: (
      1024px: 200px,
      2560px: 250px
    );
    @include fs('padding-top', $map);
  }
  .intro, h1 {
    br {
      display: none;
      @screen md {
        display: block;
      }
    }
  }
  h2, h3, h4, h5 {
    margin-bottom: 10px;
    @screen 3xl {
      @include cm-fs('margin-bottom', 10px);
    }
  }

  &.header-testimony {
    .header-testimony-media {
      .text-wrapper {
        padding: 20px;
        @screen lg {
          padding: 40px;
        }
        @screen xl {
          padding: 60px;
        }
        @screen 3xl {
          @include cm-fs('padding', 60px);
        }
      }
    }
  }
}