.block-reassurance {
  .card {
    img {
      height: 128px;
      width: auto;
      @screen 3xl {
        @include cm-fs('height', 128px);
      }
    }
  }
}