$font-path: '../../../public/fonts';

// FONTS ICOMOON
$icomoon-font-family: "brainLight-icons" !default;
$icomoon-font-path: $font-path + '/Icons' !default;

$icon-bLMobile: unquote('"\\e92a"');
$icon-RelaxTower: unquote('"\\e92b"');
$icon-showroom-path1: unquote('"\\e920"');
$icon-showroom-path2: unquote('"\\e922"');
$icon-reseller: unquote('"\\e923"');
$icon-minus: unquote('"\\e921"');
$icon-backward: unquote('"\\e929"');
$icon-forward: unquote('"\\e928"');
$icon-sphere: unquote('"\\e924"');
$icon-flow: unquote('"\\e925"');
$icon-gravity: unquote('"\\e926"');
$icon-zenesse: unquote('"\\e927"');
$icon-pause: unquote('"\\e91f"');
$icon-play-no-circle: unquote('"\\e91e"');
$icon-check: unquote('"\\e91d"');
$icon-plus: unquote('"\\e91c"');
$icon-branch: unquote('"\\e918"');
$icon-cart-2: unquote('"\\e91b"');
$icon-marker: unquote('"\\e913"');
$icon-company: unquote('"\\e914"');
$icon-job: unquote('"\\e915"');
$icon-id: unquote('"\\e916"');
$icon-play: unquote('"\\e917"');
$icon-linkedin: unquote('"\\e912"');
$icon-cool-hands: unquote('"\\e90f"');
$icon-shiny-head: unquote('"\\e910"');
$icon-blink-2: unquote('"\\e911"');
$icon-pin: unquote('"\\e90e"');
$icon-quote: unquote('"\\e90d"');
$icon-arrow-down: unquote('"\\e907"');
$icon-chevron: unquote('"\\e908"');
$icon-btn-arrow: unquote('"\\e909"');
$icon-star: unquote('"\\e90a"');
$icon-big-arrow-right: unquote('"\\e90b"');
$icon-big-arrow-left: unquote('"\\e90c"');
$icon-accessories: unquote('"\\e905"');
$icon-programms: unquote('"\\e906"');
$icon-system: unquote('"\\e919"');
$icon-armchair: unquote('"\\e91a"');
$icon-instagram: unquote('"\\e901"');
$icon-twitter: unquote('"\\e902"');
$icon-youtube: unquote('"\\e903"');
$icon-facebook: unquote('"\\e904"');
$icon-arrow: unquote('"\\e900"');
$icon-cart: unquote('"\\e93a"');
$icon-sound-on: unquote('"\\ea26"');
$icon-sound-off: unquote('"\\ea2a"');


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?amiq3t');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?amiq3t#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?amiq3t') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?amiq3t') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?amiq3t##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bLMobile {
  &:before {
    content: $icon-bLMobile;
  }
}
.icon-RelaxTower {
  &:before {
    content: $icon-RelaxTower;
  }
}
.icon-showroom .path1 {
  &:before {
    content: $icon-showroom-path1;
    color: rgb(231, 145, 144);
  }
}
.icon-showroom .path2 {
  &:before {
    content: $icon-showroom-path2;
    margin-left: -0.7548828125em;
    color: rgb(255, 255, 255);
  }
}
.icon-reseller {
  &:before {
    content: $icon-reseller;
    color: #fff;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-backward {
  &:before {
    content: $icon-backward;
  }
}
.icon-forward {
  &:before {
    content: $icon-forward;
  }
}
.icon-sphere {
  &:before {
    content: $icon-sphere;
  }
}
.icon-flow {
  &:before {
    content: $icon-flow;
  }
}
.icon-gravity {
  &:before {
    content: $icon-gravity;
  }
}
.icon-zenesse {
  &:before {
    content: $icon-zenesse;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-play-no-circle {
  &:before {
    content: $icon-play-no-circle;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-branch {
  &:before {
    content: $icon-branch;
  }
}
.icon-cart-2 {
  &:before {
    content: $icon-cart-2;
  }
}
.icon-marker {
  &:before {
    content: $icon-marker;
  }
}
.icon-company {
  &:before {
    content: $icon-company;
  }
}
.icon-job {
  &:before {
    content: $icon-job;
  }
}
.icon-id {
  &:before {
    content: $icon-id;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-cool-hands {
  &:before {
    content: $icon-cool-hands;
  }
}
.icon-shiny-head {
  &:before {
    content: $icon-shiny-head;
  }
}
.icon-blink-2 {
  &:before {
    content: $icon-blink-2;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
.icon-quote {
  &:before {
    content: $icon-quote;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-chevron {
  &:before {
    content: $icon-chevron;
  }
}
.icon-btn-arrow {
  &:before {
    content: $icon-btn-arrow;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-big-arrow-right {
  &:before {
    content: $icon-big-arrow-right;
  }
}
.icon-big-arrow-left {
  &:before {
    content: $icon-big-arrow-left;
  }
}
.icon-accessories {
  &:before {
    content: $icon-accessories;
  }
}
.icon-programms {
  &:before {
    content: $icon-programms;
  }
}
.icon-system {
  &:before {
    content: $icon-system;
  }
}
.icon-armchair {
  &:before {
    content: $icon-armchair;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-sound-on {
  &:before {
    content: $icon-sound-on;
  }
}
.icon-sound-off {
  &:before {
    content: $icon-sound-off;
  }
}

