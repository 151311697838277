.block-technologies {
  .techno-desktop {
    .item {
      &:nth-child(3n+1), &:nth-child(3n) {
        transform: translateY(-15px);
      }
      &:nth-child(3n+2) {
        transform: translateY(15px);
      }
      @screen xl {
        &:nth-child(4n+1), &:nth-child(4n+3) {
          transform: translateY(-15px);
        }
        &:nth-child(4n), &:nth-child(4n+2) {
          transform: translateY(15px);
        }
      }
    }
  }

  .technology-card {
    .title {
      @include fs('min-height', (375px: 72px, 1023px: 82px))
    }
  }
}