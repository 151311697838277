.block-hp-blsystems {
  @screen lg {
    $map: (
      1024px: 775px,
      1728px: 955px,
      2560px: 1240px
    );
    @include fs('height', $map);
  }
  .swiper {
    overflow: visible;
    .swiper-slide {
      transition: all ease .3s;
      &:not(.swiper-slide-active) {
        transform: scale(.95);
        opacity: 0
      }
    }
  }
  .bl-system-slide {
    .img-wrapper {
      height: 250px;
      @screen lg {
        $map: (
          1024px: 256px,
          1728px: 433px,
          2560px: 641px
        );
        @include fs('height', $map);
      }
      img {
        width: auto;
        height: 100%;
      }
    }
    .slide-title {
      $map: (375px: 43px, 1536px: 49px, 2560px: 80px);
      @include fs('font-size', $map);
      &.font-Mathilde {
        $map: (375px: 50px, 1536px: 60px, 2560px: 100px);
        @include fs('font-size', $map);
      }
      &.font-Sphere, &.font-Mathilde, &.font-NellaSue, &.font-Futura {
        letter-spacing: initial;
        line-height: 100%;
      }
      &.font-Mathilde, &.font-NellaSue {
        text-transform: capitalize;
      }
      &.font-Sphere, &.font-Futura {
        $map: (375px: 51px, 1536px: 57px, 2560px: 89px);
        @include fs('font-size', $map);
      }
      &.font-Mathilde {
        $map: (375px: 90px, 1536px: 100px, 2560px: 120px);
        @include fs('font-size', $map);
        line-height: 57%;
      }
      &.font-NellaSue {
        $map: (375px: 60px, 1536px: 68px, 2560px: 90px);
        @include fs('font-size', $map);
        line-height: 90%;
      }
    }
    .slide-desc, .slide-desc p {
      $map: (375px: 18px, 1536px: 20px, 1728px: 22.5px, 1920px: 25px, 2220px: 28px, 2560px: 33px);
      @include fs('font-size', $map);
      font-weight: 200;
      line-height: 150%;
      color: white;
      br {
        display: none;
        @screen lg {
          display: block;
        }
      }
    }
  }
}