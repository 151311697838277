.base-layout.bg-white {
  // header
  header {
    // menu
    .menu-wrapper {
      .burger-group {
        .burger-wrapper {
          .line {
            background: theme('colors.primary');
          }
        }
        .burger-menu-text {
          color: theme('colors.primary');
        }

        &:hover {
          .burger-wrapper-wrapper {
            background-color: theme('colors.primary');
            .line {
              background-color: white;
            }
          }
        }
      }
    }

    // background audio
    .background-audio {
      .icon {
        color: theme('colors.primary');
        &:hover {
          color: theme('colors.action');
        }
      }
    }

    // btns
    .btn-shop {
      span {
        color: theme('colors.primary');
      }
    }

    // header mini
    &.header-mini {
      .menu-wrapper {
        .burger-wrapper {
          .line {
            background: theme('colors.white');
          }
        }
        .burger-menu-text {
          color: theme('colors.white');
        }
      }
      .menu-audio-sep {
        background-color: rgba(white, .3);
      }
      .background-audio {
        .icon {
          color: theme('colors.white');
          &:hover {
            color: theme('colors.action');
          }
        }
      }
      .logo-wrapper {
        .primary {
          opacity: 1;
        }
        .secondary {
          opacity: 0;
        }
      }
      .btn-shop {
        span {
          color: theme('colors.white');
        }
        &:hover {
          span {
            color: theme('colors.primary');
          }
        }
      }
    }
  }

  // breadcrumbs
  .breadcrumbs {
    a {
      color: theme('colors.primary');
      &:not(:first-child) {
        &:before {
          background: theme('colors.primary');
          opacity: .3;
        }
      }
      &:hover {
        color: theme('colors.action')
      }
    }
  }


  // typography
  h1, h2, h3, h4, h5, p {
    color: theme('colors.primary');
  }

}