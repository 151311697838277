.block-rewards-details {
  .col-t {
    padding-top: 20px;
    @screen xs {
      padding-top: 40px;
    }
  }
  .col-b {
    padding-bottom: 20px;
    @screen xs {
      padding-bottom: 40px;
    }
  }
  .col-t, .col-b {
    @screen md {
      padding: 40px 0;
    }
    @screen 3xl {
      $map: (
        1728px: 40px,
        1920px: 44px,
        2220px: 51px,
        2560px: 59px
      );
      @include fs('padding-top', $map);
      @include fs('padding-bottom', $map);
    }
  }
}