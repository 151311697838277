.cookies-container {
  width: calc(100% - 32px);
  left: 16px;
  bottom: 16px;
  z-index: 60;
  @include fs('padding', (320px: 16px, 1280px: 32px));
  @include fs('gap', (320px: 8px, 1280px: 16px));
  background-color: theme('colors.primary');
  border: 1px solid theme('colors.action');
  border-radius: 36px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.10);
  @screen md {
    width: 65%;
  }
  @screen lg {
    width: 55%;
    bottom: 20px;
    left: 20px;
  }
  @screen 2xl {
    width: 45%;
    @include cm-fs('padding', 32px);
    @include cm-fs('bottom', 20px);
    @include cm-fs('left', 20px);
    @include cm-fs('border-radius', 36px);
  }
  @screen 3xl {
    width: 33%;
  }
  .wrapper-content {
    margin-bottom: 32px;
    @screen 2xl {
      @include cm-fs('margin-bottom', 32px);
    }
    a {
      &:hover {
        color: theme('colors.black');
      }
    }
    h2 {
      @include fs('margin-bottom', (320px: 15px, 1280px: 20px));
      @include fs('font-size', (320px: 18px, 1280px: 22px));
      @screen 2xl {
        @include cm-fs('font-size', 20px);
        @include cm-fs('margin-bottom', 20px);
      }
    }
    h2, p {
      color: theme('colors.white')
    }
  }
  .wrapper-btn {
    @include fs('gap', (320px: 8px, 1280px: 16px));
    @screen 2xl {
      @include cm-fs('gap', 16px);
    }

    .btn, .popup-wrapper {
      @screen sm {
        flex: 1;
        &.btn-tertiary {
          width: 100%;
        }
      }
    }
  }
  .popup-cookie {
    .content-wrapper {
      .top-cookie {
        .h2 {
          font-weight: 700;
        }
        .cross-close-cookie {
          width: 50px;
          height: 50px;
          z-index: 1;
          &:hover {
            .cross-1 {
              transform: translate(-50%,-50%) rotate(45deg) scale(1.2);
            }
            .cross-2 {
              transform: translate(-50%,-50%) rotate(-45deg) scale(1.2);
            }
            cursor: pointer;
          }
          .cross-1, .cross-2 {
            @include transition-fx;
            width: 100%;
            color: theme('colors.black');
            height: 100%;
            width: 2px;
            height: 30px;
            background-color: theme('colors.black');
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
          }
          .cross-1 {
            transform: translate(-50%,-50%) rotate(45deg);
          }
          .cross-2 {
            transform: translate(-50%,-50%) rotate(-45deg);
          }
        }
      }
      .list-cookies {
        ul {
          li {
            padding: 15px;
            margin-bottom: 15px !important;
            border: 1px solid theme('colors.white');
            border-radius: 10px;
            cursor: pointer;
            &:before {
              display: none;
            }
            .content-cookie {
              height: 0;
              opacity: 0;
              p {
                font-size: 16px !important;
              }
            }
            p {
              &.list-name {
                font-size: 18px !important;
              }
            }
            .cookie-item {
              flex-basis: 66%;
            }
            .btn-checkbox-cookie {
              width: 65px;
              height: 25px;
              .knobs {
                position: absolute;
                top: 0;
                z-index: 2;
                &:before {
                  content: '';
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  width: 20px;
                  padding: 9px 4px;
                  background-color: theme('colors.primary');
                  border-radius: 50%;
                  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
                  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.35), 0 10px 10px -5px rgba(0, 0, 0, 0.20);
                }
              }
              .checkbox {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 3;
                &:active {
                  .knobs {
                    &:before {
                      width: 46px;
                      border-radius: 100px;
                    }
                  }
                }
                &:checked {
                  &:active {
                    + {
                      .knobs {
                        &:before {
                          margin-left: -26px;
                        }
                      }
                    }
                  }
                  + {
                    .knobs {
                      &:before {
                        content: '';
                        left: 32px;
                        background-color: theme('colors.primary');
                      }
                    }
                  }
                  ~ {
                    .layer {
                      background-color: theme('colors.white');
                    }
                  }
                }
              }
              .layer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: theme('colors.white');
                transition: 0.3s ease all;
                z-index: 1;
                border-radius: 100px;
              }
            }
          }
        }
      }
      .wrapper-btn-cookies {
        gap: 20px;
        justify-content: space-evenly;
      }
    }
  }
}