.block-hp-whybl {
  h2 {
    @screen lg {
      $map: (
        1024px: 30px,
        1536px: 45px,
        1728px: 51px,
        1920px: 57px,
        2220px: 66px,
        2560px: 76px
      );
      @include fs('font-size', $map);
    }
  }
  .card {
    img {
      width: auto !important;
      height: 128px !important;
      @screen 3xl {
        @include cm-fs('height', 128px);
      }
    }
  }

  .scrolling-sentence {
    .sentence, .icon {
      margin-right: 15px;
      @screen md {
        margin-right: 30px;
      }
      @screen lg {
        margin-right: 45px;
      }
      @screen 3xl {
        @include cm-fs('margin-right', 45px);
      }
    }
  }
}