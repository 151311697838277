.block-hp-slider-technology {
  .inside {
    height: calc(100vh - 90px);
    min-height: 700px;
    @screen lg {
      height: calc(100vh - 104px);
    }
    @screen 5xl {
      height: calc(100vh - 108px);
    }
    @screen 6xl {
      height: calc(100vh - 115px);
    }
  }

  .bg-wrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .number {
    width: 138px;
    aspect-ratio: 165/107;
    &.index-1 {
      background: url('../../../../public/images/svgs/01.svg') no-repeat center;
      background-size: cover;
    }
    &.index-2 {
      background: url('../../../../public/images/svgs/02.svg') no-repeat center;
      background-size: cover;
    }
    &.index-3 {
      background: url('../../../../public/images/svgs/03.svg') no-repeat center;
      background-size: cover;
    }
  }

  h3.slide-title {
    font-size: 28px;
    @screen lg {
      font-size: 56px;
    }
    @screen xl {
      font-size: 70px;
    }
    @screen 2xl {
      $map: (
        1536px: 75px,
        1728px: 75px,
        1920px: 83px,
        2220px: 96px,
        2560px: 111px);
      @include fs('font-size', $map);
    }
  }

  .techno-slide-btn {
    @screen lg {
      margin-top: 30px;
    }
    @screen 2xl {
      @include cm-fs('margin-top', 45px);
    }
  }

  .swiper-tech-pagination {
    .swiper-pagination-bullet {
      @screen lg {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
        border: 0;
        @include transition-fx;
        span {
          font-size: 10px;
          font-weight: 300;
          color: white;
          letter-spacing: .3em;
          transform: translateX(2px);
        }
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 160px;
          &:after {
            content: '';
            display: block;
            width: 1px;
            height: 140px;
            position: absolute;
            top: 100%;
            left: 50%;
            background: white;
            opacity: .1;
            transform: translate(-50%, 10px);
          }
        }
        &.swiper-pagination-bullet-active, &:hover {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.196) 100%);
          backdrop-filter: blur(5px);
          &:before {
            content: '';
            width: 4px;
            height: 4px;
            position: absolute;
            top: 50%;
            right: 0;
            border-radius: 50%;
            background: #D9D9D9;
            transform: translate(10px, -50%);
          }
        }
        &:hover {
          cursor: pointer;
        }
      }

      @screen 3xl {
        &:not(:last-child) {
          margin-bottom: 220px;
          &:after {
            height: 200px;
          }
        }
      }
    }
  }

  .technology-frame {
    .tf-title {
      font-size: 18px;
      line-height: 166%;
      letter-spacing: 5.4px;
      @screen lg {
        font-size: 16px;
      }
      @screen xl {
        font-size: 18px;
      }
      @screen 3xl {
        @include cm-fs('font-size', 18px);
        @include cm-fs('letter-spacing', 5.4px);
      }
    }
    .tf-text {
      padding-top: 10px;
      @screen 3xl {
        @include cm-fs('padding-top', 10px);
      }
    }
  }
}