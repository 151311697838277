// Wysiwyg
.wysiwyg {
  a {
    text-decoration: underline;
  }
  h1, h2, h3, h4, h5 {
    margin-bottom: 30px;
    @screen 3xl {
      $map: (
        1728px: 30px,
        1920px: 33px,
        2220px: 38px,
        2560px: 44px
      );
      @include fs('margin-bottom', $map);
    }
  }
  p {
    &:not(:last-child) {
      margin-bottom: 20px;
      @screen 3xl {
        $map: (
          1728px: 20px,
          1920px: 22px,
          2220px: 25px,
          2560px: 29px
        );
        @include fs('margin-bottom', $map)
      }
    }
  }
  blockquote {
    &:not(:first-child) {
      margin-top: 30px;
      @screen 3xl {
        $map: (
          1728px: 30px,
          1920px: 33px,
          2220px: 38px,
          2560px: 44px
        );
        @include fs('margin-top', $map)
      }
    }
    p {
      @extend .ft-20;
      font-style: italic;
      font-weight: 500;
      line-height: 150%;
      color: theme('colors.secondary');
    }
  }

  ol {
    counter-reset: listCounter;
    li {
      position: relative;
      counter-increment: listCounter;
      margin-bottom: 35px;
      padding-left: 60px;
      @extend p;
      &:before {
        content: counter(listCounter, decimal-leading-zero);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.20) 100%);
        backdrop-filter: blur(5px);
        letter-spacing: 3px;
        color: white;
        @extend .ft-10;
      }
    }
  }

  &.blog {
    blockquote {
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 167%;
        @screen 3xl {
          $map: (
            1728px: 16px,
            1920px: 17px,
            2220px: 20px,
            2560px: 23px
          );
          @include fs('font-size', $map);
        }
        color: theme('colors.text');
      }
    }
  }
}