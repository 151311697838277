.header-testimony-media {
  &.in-play {
    .btn-wrapper, .text-wrapper, .custom-poster {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.block-video {
  &.in-play {
    .video-wrapper {
      height: auto;
      aspect-ratio: 21/9;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      .custom-poster {
        opacity: 0;
        pointer-events: none;
      }
      video, iframe {
        opacity: 1;
      }
    }
    .container {
      opacity: 0;
      pointer-events: none;
    }
  }
}