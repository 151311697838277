.audio-component {
  .progress-bar {
    .not-filled {
      background: url(../../../../public/images/svgs/progress-audio.svg) left center;
    }
    .filled {
      background: url(../../../../public/images/svgs/progress-audio-filled.svg) left center;
    }
  }
}

.header-programm-card {
  .audio-component {
    .ac-wrapper, .icon-wrapper {
      border-color: theme('colors.white');
    }
    .icon, .time {
      color: theme('colors.white');
    }
    .progress-bar {
      filter: invert(100%);
    }
  }
}