//Mixins

@mixin transition-fx {
  transition: all ease 0.3s;
}

// Cross multiplication
@function cm($base-size) {
  $base-screen-size: 1728px;
  $screen-sizes: (1728px, 1920px, 2220px, 2560px);
  $calculated-sizes: ();

  @each $screen-size in $screen-sizes {
    $calculated-size: ceil($base-size * ($screen-size / $base-screen-size));
    $calculated-sizes: map-merge($calculated-sizes, ($screen-size: $calculated-size));
  }

  @return $calculated-sizes;
}

/// fs
/// Generate linear interpolated size values through multiple break points
/// @param $property - A string CSS property name
/// @param $map - A SASS map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include fs('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@mixin fs($property, $map) {
  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error "fs() $map requires at least values"
  }

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys,1));

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    @media (min-width:nth($keys,$i)) {
      #{$property}: linear-interpolation((nth($keys,$i): map-get($map, nth($keys,$i)), nth($keys,($i+1)): map-get($map, nth($keys,($i + 1)))));
    }
  }

  // Maxmimum size
  @media (min-width:nth($keys,$length)) {
    #{$property}: map-get($map, nth($keys,$length));
  }
}

/// linear-interpolation
/// Calculate the definition of a line between two points
/// @param $map - A SASS map of viewport widths and size value pairs
/// @returns A linear equation as a calc() function
/// @example
///   font-size: linear-interpolation((320px: 18px, 768px: 26px));
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@function linear-interpolation($map) {
  $keys: map-keys($map);
  @if (length($keys) != 2) {
    @error "linear-interpolation() $map must be exactly 2 values";
  }
  // The slope
  $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)))/(nth($keys, 2) - nth($keys,1));

  // The y-intercept
  $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

  // Determine if the sign should be positive or negative
  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }

  @return calc(#{$m*100}vw #{$sign} #{$b});
}

/// cm-fs
/// Do a cross multiplication and Generate linear interpolated size values through multiple break points
/// @param $property - A string CSS property name
/// @param $map - A SASS map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include fs('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@mixin cm-fs($property, $base-size) {
  $base-screen-size: 1728px;
  $screen-sizes: (1728px, 1920px, 2220px, 2560px);
  $map: ();

  @each $screen-size in $screen-sizes {
    $calculated-size: ceil($base-size * ($screen-size / $base-screen-size));
    // $calculated-size: ceil($calculated-size * 0.);
    $map: map-merge($map, ($screen-size: $calculated-size));
  }

  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error "fs() $map requires at least values"
  }

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys,1));

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    @media (min-width:nth($keys,$i)) {
      #{$property}: linear-interpolation((nth($keys,$i): map-get($map, nth($keys,$i)), nth($keys,($i+1)): map-get($map, nth($keys,($i + 1))))) !important;
    }
  }

  // Maxmimum size
  @media (min-width:nth($keys,$length)) {
    #{$property}: map-get($map, nth($keys,$length)) !important;
  }
}

/// list-sort
/// Sort a SASS list
/// @param $list - A SASS list
/// @returns A sorted SASS list
/// @requires function list-remove
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@function list-sort($list) {
  $sortedlist: ();
  @while length($list) > 0 {
    $value: nth($list,1);
    @each $item in $list {
      @if $item < $value {
        $value: $item;
      }
    }
    $sortedlist: append($sortedlist, $value, 'space');
    $list: list-remove($list, index($list, $value));
  }
  @return $sortedlist;
}

/// map-sort
/// Sort map by keys
/// @param $map - A SASS map
/// @returns A SASS map sorted by keys
/// @requires function list-sort
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@function map-sort($map) {
  $keys: list-sort(map-keys($map));
  $sortedMap: ();
  @each $key in $keys {
    $sortedMap: map-merge($sortedMap, ($key: map-get($map, $key)));
  }
  @return $sortedMap;
}

/// list-remove
/// Remove an item from a list
/// @param $list - A SASS list
/// @param $index - The list index to remove
/// @returns A SASS list
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@function list-remove($list, $index) {
  $newList: ();
  @for $i from 1 through length($list) {
    @if $i != $index {
      $newList: append($newList, nth($list,$i), 'space');
    }
  }
  @return $newList;
}
