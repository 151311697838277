.block-compare {
  .container.overflow-scroll {
    max-width: unset;
    @screen xl {
      max-width: 1280px;
    }
    @screen 2xl {
      $map: (
        1536px: 1312px,
        1728px: 1499px,
        1920px: 1666px,
        2220px: 1926px,
        2560px: 2221px
      );
      @include fs('max-width', $map);
    }
  }
  .comparator-part {
    border-bottom: 1px solid rgba(white, .3);
    margin-bottom: 20px;
    @screen sm {
      margin-bottom: 30px;
    }
    @screen md {
      margin-bottom: 40px;
    }
    @screen lg {
      margin-bottom: 50px;
    }
    @screen 3xl {
      $map: (
        1728px: 50px,
        1920px: 55px,
        2220px: 64px,
        2560px: 72.5px
      );
      @include fs('margin-bottom', $map);
    }
    &.open {
      border-color: transparent;
      .cp-content .inside {
        overflow: visible;
      }
    }
    .cp-content .inside {
      overflow: hidden;
      transition-delay: .2;
      transition-property: overflow;
    }
    .icon {
      flex: 0 0 35px;
      height: 35px;
      background: rgba(118, 132, 137, 0.30);
      backdrop-filter: 6px;
      @screen md {
        flex: 0 0 44px;
        height: 44px;
      }
    }

    .part-none {
      width: 38px;
      height: 38px;
      position: relative;
      &:before, &:after {
        content: '';
        width: 53px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: theme('colors.white')
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}