.block-hp-for-who {
  .title {
    h2 {
      font-size: 36px;
      line-height: 100%;
      @screen lg {
        $map: (
          1024px: 51px,
          1536px: 100px,
          1728px: 106px,
          1920px: 122px,
          2220px: 136px,
          2560px: 158px
        );
        @include fs('font-size', $map);
      }
      em {
        font-style: normal;
      }
      @screen md {
        .second, .third {
          display: inline-block;
        }
        .second {
          margin-left: 15%;
        }
        .third {
          margin-left: 25%;
        }
        em {
          font-weight: 100;
        }
      }
      @screen xl {
        .second {
          margin-left: 10%;
        }
        .third {
          margin-left: 20%;
        }
      }
    }
  }
}