.block-product-submenu, .block-listing-posts {
  .products-menu, .tags-menu {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .menu-prev, .menu-next {
    &:before {
      content: '';
      width: 30px;
      height: 100%;
      position: absolute;
      top: 0;
      background: linear-gradient(90deg, theme('colors.primary') 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .menu-prev {
    &:before {
      left: 0;
      background: linear-gradient(90deg, theme('colors.primary') 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .menu-next {
    &:before {
      right: 0;
      background: linear-gradient(90deg,  rgba(255, 255, 255, 0)  0%, theme('colors.primary') 100%);
    }
  }
}