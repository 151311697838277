.popup-wrapper {
  &.default {
    .popup {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      @include fs('padding-top', (320px: 84px, 1023px: 108px, 1024px: 128px, 1280px: 157px));
      opacity: 0;
      pointer-events: none;
      background: rgba(black, .5);
      @include transition-fx;
      @screen 2xl {
        @include cm-fs('padding-top', 157px);
      }
      .popup-inner {
        width: calc(100vw - 32px);
        position: absolute;
        max-height: 80%;
        top: 50%;
        left: 50%;
        @include fs('padding-block', (320px: 40px, 1280px: 80px));
        @include fs('padding-inline', (320px: 16px, 1280px: 80px));
        overflow: scroll;
        transform: translate(-50%, -55%);
        background-color: theme('colors.primary');
        box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.10);
        @include transition-fx;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        @screen md {
          width: 80%;
        }
        @screen lg {
          width: 70%;
        }
        @screen xl {
          width: 61.5%;
        }
        @screen 2xl {
          @include cm-fs('padding', 80px);
        }
        .icon {
          @include fs('top', (375px: 10px, 1280px: 24px));
          @include fs('right', (375px: 10px, 1280px: 16px));
          @include fs('font-size', (375px: 24px, 1280px: 48px));
          line-height: 100%;
          color: theme('colors.primary');
          @include transition-fx;
          &:hover {
            color: theme('colors.action');
            cursor: pointer;
          }
          @screen 2xl {
            @include cm-fs('top', 24px);
            @include cm-fs('right', 16px);
            @include cm-fs('font-size', 48px);
          }
        }
      }
    }
  }

  &.open-true {
    &.default {
      .popup {
        pointer-events: all;
        opacity: 1;
        .popup-inner {
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}