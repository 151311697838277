.breadcrumbs {
  a {
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: .3em;
    color: rgba(white, .3);
    text-transform: uppercase;
    transition: all ease .3s;
    @screen xl {
      font-size: 10px;
    }
    @screen 2xl {
      $map: (
        1536px: 10px,
        2560px: 16.5px
      );
      @include fs('font-size', $map);
    }
    &.active, &:hover {
      cursor: pointer;
      color: theme('colors.white')
    }
    &:not(:first-child) {
      position: relative;
      padding-left: 15px;
      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 18px;
        position: absolute;
        bottom: 2px;
        left: 0;
        background: rgba(white, .3);
      }
    }
    &:nth-child(3) {
      margin-left: 15px;
    }
    &:nth-child(4) {
      margin-left: 30px;
      @extend .ft-8;
    }
  }
}