.menu-wrapper {
  .burger-group {
    &:hover {
      @screen lg {
        .burger-wrapper-wrapper {
          background-color: white;
        }
        .line {
          background-color: theme('colors.primary');
        }
      }
    }
  }
  .menu {
    height: calc(var(--vh, 1vh) * 100 - 130px);
    top: 110px;
    @screen lg {
      height: calc(var(--vh, 1vh) * 100 - 160px);
      top: 140px;
    }
    .menu-item {
      .menu-link {
        $map: (375px: 18px, 1536px: 20px, 1728px: 22px, 1920px: 25px, 2220px: 29px, 2560px: 33px);
        line-height: 175%;
        font-weight: 300;
      }
      .second-menu-icons {
        font-size: 25px;
        &.icon-system {
          font-size: 18px;
        }
        &.icon-zenesse, &.icon-gravity, &.icon-flow, &.icon-sphere {
          font-size: 32px;
        }
      }
      .menu-description {
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          top: 10px;
          left: 0;
          background-color: theme('colors.white');
          opacity: .1;
        }
      }
    }
  }
}

header {
  &.header-mini {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: theme('colors.primary');
  }

  // menu transition
  nav.menu {
    transition: all ease-out .2s .2s;
    visibility: hidden;
    ul.menu-list {
      transition: transform ease-out .2s;
      li.menu-item {
        nav.sub-menu {
          transition: all ease-out .2s;
          nav.subsub-menu {
            transition: opacity ease-out .2s;
          }
        }
      }
    }
  }

  // second level open bug
  nav.menu {
    nav.subsub-menu {
      pointer-events: none;
    }
  }

  // Menu-open
  &.menu-open {
    .burger-group {
      .burger-wrapper-wrapper {
        background-color: transparent;
      }
      .burger-menu-text {
        transform: translateX(-10px);
      }
      &:hover {
        .burger-menu-text {
          transform: translateX(0);
        }
      }
    }
    nav.menu {
      transform: translateX(calc(-100% - 20px));
      visibility: visible;
      @screen lg {
        width: 295px;
        transform: translateX(20px);

        &.first-level-open {
          width: 655px;
          .menu-item.panel-open .sub-menu {
            z-index: 100;
            opacity: 1;
          }

          &.second-level-open {
            width: 945px;
            .menu-item.subpanel-open .subsub-menu {
              z-index: 101;
              opacity: 1;
            }
            nav.subsub-menu {
              pointer-events: all;
            }
          }
        }
      }
      @screen xl {
        width: 295px;
        transform: translateX(20px);

        &.first-level-open {
          width: 813px;

          &.second-level-open {
            width: 1154px;
          }
        }
      }



      // Menu mobile
      ul.menu-list {
        &.submenu-open {
          transform: translateX(-100%);
          @screen lg {
            transform: unset;
          }
          li.menu-item.has-submenu.panel-open {
            nav.sub-menu {
              z-index: 100;
              opacity: 1;
            }
          }
        }

        nav.sub-menu {
          &.subsubmenu-open {
            transform: translateX(-100%);
            @screen lg {
              transform: unset;
            }
            li.menu-item.has-subsubmenu.subpanel-open {
              nav.subsub-menu {
                pointer-events: all;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .social-medias {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.bg-w-menu-open {
  pointer-events: none;
  transition: opacity ease-out .2s .4s;
}
body.menu-stop-scroll{
  .bg-w-menu-open {
    opacity: 1;
    transition: none;
    pointer-events: all;
  }
}