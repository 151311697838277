.scrolling-sentence {
  .sentence {
    $map: (
      375px: 31.5px,
      1536px: 90px,
      1728px: 102px,
      1920px: 114px,
      2220px: 132px,
      2560px: 152px
    );
    @include fs('font-size', $map);
    font-weight: 100;
    line-height: 117%;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .sentence, .icon {
    color: theme('colors.white');
  }
  .icon {
    $map: (
      375px: 11px,
      1536px: 28px,
      1728px: 32px,
      1920px: 35px,
      2220px: 41px,
      2560px: 47px
    );
    @include fs('font-size', $map);
  }
}