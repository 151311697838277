.block-partners {
  .partners-slider {
    .swiper-wrapper {
      align-items: center;
    }
  }
  .testimonies-slider {
    .swiper-slide {
      opacity: .3;
      transition: opacity .2 ease;
      &.swiper-slide-active {
        opacity: 1;
      }
      h5 {
        font-size: 16px;
        @screen lg {
          $map: (
            1024px: 16px,
            1536px: 25px,
            1728px: 28px,
            1920px: 31px,
            2220px: 36px,
            2560px: 42px
          );
          @include fs('font-size', $map);
        }
      }
    }
  }
}