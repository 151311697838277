.block-archive-relax-programms {
  .programm-card {
    @screen 3xl {
      $map: (
        1728px: 40px,
        1920px: 44px,
        2220px: 51px,
        2560px: 59px
      );
      $map2: (
        1728px: 60px,
        1920px: 66px,
        2220px: 77px,
        2560px: 88px
      );
      @include fs('padding-left', $map);
      @include fs('padding-right', $map);
      @include fs('padding-top', $map2);
      @include fs('padding-bottom', $map);
    }
    .sample-audio {
      .icon {
        font-size: 28px;
        @screen 3xl {
          $map: (
            1728px: 28px,
            1920px: 31px,
            2220px: 35px,
            2560px: 41px
          );
          @include fs('font-size', $map);
        }
      }
    }
    .title {
      font-size: 20px;
      @screen md {
        font-size: 16px;
      }
      @screen lg {
        font-size: 20px;
      }
      @screen xl {
        font-size: 18px;
      }
      @screen 3xl {
        $map: (
          1728px: 24px,
          1920px: 26px,
          2220px: 30px,
          2560px: 35px
        );
        @include fs('font-size', $map);
      }
    }
  }
}