.block-header-armchair {
  .product-image {
    $map: (
      1728px: 560px,
      1920px: 622px,
      2220px: 719px,
      2560px: 829px,
    );
    @include fs('max-width', $map);
  }
  .mea-carac {
    padding: 10px 15px;
    @screen md {
      padding: 20px 30px;
    }
    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.20) 100%);
      backdrop-filter: 6px;
      @screen md {
        width: 37px;
        height: 37px;
      }
    }
    &.head {
      top: 5%;
      right: 0;
      @screen sm {
        top: 10%;
      }
      @screen lg {
        right: 5%;
      }
      @screen xl {
        right: 15%;
      }
      @screen 3xl {
        top: 12.5%;
        right: 20%;
      }
      @screen 4xl {
        right: 22.5%;
      }
      @screen 5xl {
        right: 23.5%;
      }
      .icon {
        top: calc(100% + 10px);
        left: 25%;
        transform: translateX(-50%);
        @screen xs {
          top: 50%;
          left: 0;
          transform: translate(-120%, -50%);
        }
      }
    }
    &.back {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @screen xs {
        top: 36%;
      }
      @screen xl {
        left: 10%;
      }
      @screen 3xl {
        left: 15%;
      }
      @screen 4xl {
        left: 17.5%;
      }
      @screen 5xl {
        left: 18.5%;
      }
      .icon {
        top: -30px;
        left: 75%;
        transform: translateX(-50%);
        @screen xs {
          top: 50%;
          left: unset;
          right: 0;
          transform: translate(120%, -50%);
        }
      }
    }
    &.foot {
      bottom: 5%;
      right: 0;
      @screen sm {
        bottom: 10%;
      }
      @screen xl {
        right: 12.5%;
      }
      @screen 3xl {
        bottom: 20%;
        right: 15%;
      }
      @screen 4xl {
        right: 17.5%;
      }
      @screen 5xl {
        right: 20%;
      }
      .icon {
        top: -30px;
        left: 25%;
        transform: translateX(-50%);
        @screen xs {
          top: 50%;
          left: 0;
          transform: translate(-120%, -50%);
        }
      }
    }
  }
}